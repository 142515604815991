<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col>
          <h1>Articles</h1>
        </v-col>
        <v-col cols="8">
          <v-row align="center" justify="end" no-gutters>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  color="accent"
                  class="mr-2"
                  v-on="on"
                  :to="{ name: $route.name + '-create' }"
                >
                  <v-icon small>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Add New Article</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  color="warning"
                  class="mr-2"
                  v-on="on"
                  :to="{ name: $route.name + '-categories' }"
                >
                  <v-icon small>mdi-format-list-bulleted</v-icon>
                </v-btn>
              </template>
              <span>View Categories</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  light
                  v-on="on"
                  :to="{ name: $route.name + '-archived' }"
                >
                  <v-icon small>mdi-archive</v-icon>
                </v-btn>
              </template>
              <span>View Archived Articles</span>
            </v-tooltip>
          </v-row>
        </v-col>
      </v-row>
      <v-data-table
        :headers="tableHeaders"
        :items="articles"
        no-data-text="No Articles found"
      >
        <template v-slot:item.title="{ item }">
          <router-link
            :to="{
              name: 'module-news-individual',
              params: { articleId: item.id },
            }"
            >{{ item.title }}</router-link
          >
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="blue lighten-4 blue--text"
                v-on="on"
                class="mr-2"
                :to="{
                  name: 'module-news-individual',
                  params: { articleId: item.id },
                }"
              >
                <v-icon x-small>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Edit</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="red lighten-4 red--text"
                v-on="on"
                @click="openDeleteArticle(item)"
              >
                <v-icon x-small>mdi-archive</v-icon>
              </v-btn>
            </template>
            <span>Archive</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-container>
    <v-dialog v-model="deleteArticle.dialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">Delete Article</v-card-title>
        <v-card-text
          >Are you sure you wish to delete
          {{ deleteArticle.article.name }}?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetDeleteArticle"
            >No, cancel</v-btn
          >
          <v-btn
            color="success"
            depressed
            :loading="deleteArticle.loading"
            @click="saveDeleteArticle"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      breadcrumbs: [
        {
          text: "News",
          disabled: true,
        },
      ],
      tableHeaders: [
        { text: "Title", value: "title" },
        { text: "Author", value: "author_name" },
        { text: "Status", value: "status" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
      deleteArticle: {
        dialog: false,
        article: {},
        loading: false,
      },
    };
  },

  computed: {
    articles() {
      return this.$store.state.news["articles"];
    },
  },

  methods: {
    openDeleteArticle(article) {
      this.deleteArticle.article = article;
      this.deleteArticle.dialog = true;
    },

    resetDeleteArticle() {
      this.deleteArticle.dialog = false;
      this.deleteArticle.article = {};
      this.deleteArticle.loading = false;
    },

    saveDeleteArticle() {
      this.deleteArticle.loading = true;

      this.$store
        .dispatch("news/deleteArticle", {
          appId: this.$route.params.id,
          articleId: this.deleteArticle.article.id,
        })
        .then(() => {
          this.resetDeleteArticle();
        })
        .catch(() => {
          this.deleteArticle.loading = false;
        });
    },
  },
};
</script>
