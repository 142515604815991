var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-container',[_c('v-breadcrumbs',{staticClass:"pa-0 pb-2",attrs:{"items":_vm.breadcrumbs,"divider":">"}}),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',[_c('h1',[_vm._v("Articles")])]),_c('v-col',{attrs:{"cols":"8"}},[_c('v-row',{attrs:{"align":"center","justify":"end","no-gutters":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"depressed":"","color":"accent","to":{ name: _vm.$route.name + '-create' }}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v("Add New Article")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"depressed":"","color":"warning","to":{ name: _vm.$route.name + '-categories' }}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-format-list-bulleted")])],1)]}}])},[_c('span',[_vm._v("View Categories")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"depressed":"","light":"","to":{ name: _vm.$route.name + '-archived' }}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-archive")])],1)]}}])},[_c('span',[_vm._v("View Archived Articles")])])],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.tableHeaders,"items":_vm.articles,"no-data-text":"No Articles found"},scopedSlots:_vm._u([{key:"item.title",fn:function({ item }){return [_c('router-link',{attrs:{"to":{
            name: 'module-news-individual',
            params: { articleId: item.id },
          }}},[_vm._v(_vm._s(item.title))])]}},{key:"item.actions",fn:function({ item }){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"x-small":"","depressed":"","color":"blue lighten-4 blue--text","to":{
                name: 'module-news-individual',
                params: { articleId: item.id },
              }}},on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"x-small":"","depressed":"","color":"red lighten-4 red--text"},on:{"click":function($event){return _vm.openDeleteArticle(item)}}},on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-archive")])],1)]}}],null,true)},[_c('span',[_vm._v("Archive")])])]}}])})],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"400"},model:{value:(_vm.deleteArticle.dialog),callback:function ($$v) {_vm.$set(_vm.deleteArticle, "dialog", $$v)},expression:"deleteArticle.dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Delete Article")]),_c('v-card-text',[_vm._v("Are you sure you wish to delete "+_vm._s(_vm.deleteArticle.article.name)+"?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","depressed":""},on:{"click":_vm.resetDeleteArticle}},[_vm._v("No, cancel")]),_c('v-btn',{attrs:{"color":"success","depressed":"","loading":_vm.deleteArticle.loading},on:{"click":_vm.saveDeleteArticle}},[_vm._v("Yes")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }